<template>
    <section class="app">
        <WebHeader 
            active="contect" 
            :logo="false"
            active-text-color="#2A5082"
            logo-color="#2A5082"
            text-color="#666" />

        <section class="banner">
            <div class="content">
                <div class="text">
                    <h2>联系我们</h2>
                    <p>如果你有项目需要开发 有产品需要营销 欢迎联系我们</p>
                </div>
                <div class="image">
                    <img src="../../assets/images/connect.svg" />
                </div>
            </div>
        </section>


        <div class="form"></div>



        <WebFooter></WebFooter>
    </section>
</template>


<style lang="scss" scoped>
.app {
    background: #fff;
    .banner {
        width: 100%;
        background: linear-gradient(rgb(255, 255, 255) 0%, rgb(255, 255, 255) 12.55%, rgb(250, 250, 250) 100%);
        min-width: 980px;
        overflow: hidden;
        margin-top: -80px;

        .content {
            max-width: 1120px;
            min-width: 320px;
            padding: 0 20px;
            margin: 0 auto;
            padding: 150px 0 100px 0;
            position: relative;
            display: flex;

            .text{
                flex: 1;
                h2 {
                    font-size: 40px;
                    letter-spacing: .04em;
                    color: #000;
                    position: relative;

                    &:before {
                        content: "";
                        width: 50px;
                        height: 5px;
                        background-color: #0F5BFB;
                        position: absolute;
                        left: 0;
                        top: -10px;
                    }
                }
                p{
                    color: rgba(0, 0, 0, 0.75);
                }
            }

            .image{
                img{
                    width: 300px;
                }
            }
        }

    }

    .form{
        padding: 50px;
        
    }

}
</style>


<script>
import WebHeader from "../../components/web-header.vue";
import WebFooter from "../../components/web-footer.vue";
export default {
    components: { WebHeader,WebFooter }
};
</script>